import request from '@/utils/request'

export function setConfig(data) {
  return request({
    url: '/work/shop/setConfig',
    method: 'post',
    data,
  })
}
export function setSmsInfo(data) {
  return request({
    url: '/work/shop/setOrderRoomSms',
    method: 'post',
    params: data,
  })
}
export function getConfig() {
  return request({
    url: '/work/shop/getConfig',
    method: 'get',
  })
}
