<!--
 * @Author: zhouxingtong
 * @Email: 495144492@qq.com
 * @Date: 2024-03-14 16:28:08
 * @LastEditors: zhouxingtong
 * @LastEditTime: 2024-07-26 16:53:32
 * @Description: 
-->
<template>
	<div class="container">
		<a-form-model ref="form" :model="form" :wrapper-col="{span: 24}">
			<a-form-model-item v-for="(item, index) in form.mealList" :key="index">
				<div class="item">
					<a-checkbox v-model="item.open" />
					<a-input :maxLength="3" :disabled="!item.open" placeholder="请输入餐段名称" class="item__input" v-model="item.name" allowClear />
					<div class="item__picker">
						<!-- <a-time-picker :disabled="!item.open" :disabledHours="getDisabledHours(index, 'start')" :disabledMinutes="getDisabledMinutes(index, 'start')" :minute-step="30" v-model="item.time_ranger[0]" valueFormat="HH:mm" format="HH:mm" @change="onChangeTime(index, 'start')" />
						<p class="line">~</p>
						<a-time-picker :disabled="!item.open" :disabledHours="getDisabledHours(index, 'end')" :disabledMinutes="getDisabledMinutes(index, 'end')" :minute-step="30" v-model="item.time_ranger[1]" valueFormat="HH:mm" format="HH:mm" @change="onChangeTime(index, 'end')" /> -->
						<a-time-picker :disabled="!item.open" :minute-step="30" v-model="item.time_ranger[0]" valueFormat="HH:mm" format="HH:mm" @change="onChangeTime(index, 'start')" />
						<p class="line">~</p>
						<a-time-picker :disabled="!item.open" :minute-step="30" v-model="item.time_ranger[1]" valueFormat="HH:mm" format="HH:mm" @change="onChangeTime(index, 'end')" />
					</div>
				</div>
			</a-form-model-item>
			<a-form-model-item :wrapper-col="{span: 2, offset: 22}">
				<a-button block type="primary" size="large" class="save" @click="showModal">保存</a-button>
			</a-form-model-item>
		</a-form-model>
	</div>
</template>

<script>
import {setConfig, getConfig} from '@/api/personalization'
import {Modal} from 'ant-design-vue'
import moment from 'moment'

const range = (start, end) => {
	const result = []
	for (let i = start; i < end; i++) {
		result.push(i)
	}
	return result
}

export default {
	name: 'MealSetting',
	components: {},
	data() {
		return {
			form: {
				mealList: []
			},
			originMealList: [],
			timeList: [],
			timeDate: ''
		}
	},
	created() {
		this.$nextTick(() => {
			this.fetchMealSetting()
		})
	},
	methods: {
		moment,
		async fetchMealSetting() {
			this.$store.dispatch('loading/startContentLoading')
			const {code, data = {}} = await getConfig()
			if (code === 0 && data.meal_segment) {
				// 获取当前日期
				this.timeDate = new Date().toISOString().slice(0, 10)
				this.originMealList = JSON.parse(JSON.stringify(data.meal_segment))
				this.form.mealList = JSON.parse(JSON.stringify(data.meal_segment))
				// 展开全部的时间
				this.form.mealList.forEach(item => {
					let tempTimeList = this.filterArriveTimeRanger(new Date(`${this.timeDate} ${item.time_ranger[0]}`), new Date(`${this.timeDate} ${item.time_ranger[1]}`))
					this.timeList.push(...tempTimeList)
				})
			}

			this.$store.dispatch('loading/endContentLoading')
		},
		/**
		 * @description: 过滤出时间区间
		 * @return {*}
		 * @param {*} startTime
		 * @param {*} endTime
		 */
		filterArriveTimeRanger(startTime, endTime) {
			let times = []
			let currentTime = startTime.getTime()
			while (currentTime <= endTime.getTime()) {
				let time = new Date(currentTime)
				times.push(time.toTimeString().substring(0, 5)) // 获取时间的HH:mm格式
				currentTime += 30 * 60 * 1000 // 增加30分钟
			}
			return times
		},
		/**
		 * @description: 选择时间(互斥)
		 * @return {*}
		 */
		onChangeTime(mealName, type) {
			// 获取当前选择的餐段和时间范围
			const meal = this.form.mealList[mealName]
			const [startTime, endTime] = meal.time_ranger

			// 确保结束时间大于开始时间
			if (startTime && endTime && startTime >= endTime) {
				// 如果开始时间大于或等于结束时间，重置相应时间
				if (type === 'start') {
					meal.time_ranger[1] = null
				} else {
					meal.time_ranger[0] = null
				}
				this.$message.error('开始时间不能大于结束时间')

				return
			}

			// // 更新其他餐段的可选时间范围，同时排除当前正在编辑的餐段
			// this.updateDisabledRanges(mealName)
		},
		/**
		 * @description: 更新Picker中禁止选择的时间（暂时隐藏）
		 * @return {*}
		 */
		updateDisabledRanges(editingMealName) {
			// 重置所有餐段的禁用时间范围
			for (const mealName in this.form.mealList) {
				this.form.mealList[mealName].disabledRanges = []
			}

			// 将除了当前正在编辑的餐段之外的所有餐段的时间范围禁用到其他餐段
			for (const mealName in this.form.mealList) {
				if (mealName !== editingMealName) {
					const meal = this.form.mealList[mealName]
					const [start, end] = meal.time_ranger

					if (start && end) {
						// 将当前餐段的禁用时间范围应用到其他餐段
						for (const otherMealName in this.form.mealList) {
							if (otherMealName !== mealName) {
								this.form.mealList[otherMealName].disabledRanges.push({start, end})
							}
						}
					}
				}
			}
		},
		getDisabledHours(mealName, type) {
			const disabledRanges = this.form.mealList[mealName].disabledRanges || []

			// 返回函数，该函数返回需要禁用的小时数组
			return () => {
				const disabledHours = []

				// 遍历禁用的时间范围，并将禁用范围内的小时添加到数组
				disabledRanges.forEach(range => {
					const startHour = parseInt(range.start.split(':')[0])
					const endHour = parseInt(range.end.split(':')[0])

					for (let hour = startHour; hour < endHour; hour++) {
						if (!disabledHours.includes(hour)) {
							disabledHours.push(hour)
						}
					}

					// 当结束时间的分钟是30且时间是整点时，禁用结束时间
					if (parseInt(range.end.split(':')[1]) === 30 && type === 'end') {
						disabledHours.push(endHour)
					}
				})

				// 如果是结束时间选择，则禁用超过23的小时
				if (type === 'end') {
					for (let hour = 24; hour <= 23; hour++) {
						disabledHours.push(hour)
					}
				}

				return disabledHours
			}
		},
		getDisabledMinutes(mealName, type) {
			const disabledRanges = this.form.mealList[mealName].disabledRanges || []

			// 返回函数，该函数返回需要禁用的分钟数组
			return hour => {
				const disabledMinutes = []

				// 遍历禁用的时间范围，并将禁用范围内的分钟添加到数组
				disabledRanges.forEach(range => {
					const [startHour, startMinute] = range.start.split(':')
					const [endHour, endMinute] = range.end.split(':')

					// 如果当前小时在禁用范围内，计算需要禁用的分钟
					if (hour === parseInt(startHour)) {
						for (let minute = parseInt(startMinute); minute < 60; minute++) {
							if (minute % 30 !== 0) {
								disabledMinutes.push(minute)
							}
						}
					}

					if (hour === parseInt(endHour)) {
						// 禁用结束时间的分钟
						if (endMinute % 30 !== 0 && type === 'end') {
							disabledMinutes.push(parseInt(endMinute))
						}
					}
				})

				// 对每半小时进行校正
				if (type === 'end' && hour === 23) {
					disabledMinutes.push(30)
				}

				return disabledMinutes
			}
		},
		/**
		 * @description: 保存弹窗
		 * @return {*}
		 */
		showModal() {
			// 校验表单
			let tempList = JSON.parse(JSON.stringify(this.form.mealList))
			let filterResult = tempList.filter(item => item.open).every(item => item.name && item.time_ranger.every(item_child => item_child))

			if (filterResult) {
				Modal.confirm({
					title: '提示',
					content: '确认保存配置吗？',
					onOk: async () => {
						await this.onSubmit()
					}
				})
			} else {
				this.$message.error('已启用的餐段不允许有空值！')
			}
		},
		/**
		 * @description: 保存表单内容
		 * @return {*}
		 */
		async onSubmit() {
			this.$message.loading({
				content: '正在保存中...',
				key: 'save',
				duration: 0
			})
			// 转换字段
			const {code} = await setConfig({
				config_name: 'meal_segment',
				config_value: this.form.mealList
			})
			if (code === 0) {
				this.$message.success({content: '保存成功！', key: 'save'})

				this.fetchMealSetting()
			}
		}
	}
}
</script>

<style scoped lang="less">
.container {
	display: flex;
	flex-direction: column;
	padding: 25px 20px 10px 50px;
	max-width: 100% !important;
	background-color: #fff;
	border-radius: 4px;
	box-sizing: border-box;
	.item {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		margin-top: 10px;
		.item__input {
			margin: 0 20px;
			width: 200px;
		}
		.item__picker {
			display: flex;
			justify-content: center;
			align-items: center;
		}
		.line {
			margin: 0 8px;
		}
	}
	.save {
		margin-top: 50px;
	}
}
</style>
